import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const locations = {
  'Singapore': [1.278790, 103.844353],
  'Bali': [-8.670458, 115.212631],
  'Koh Tao': [10.0922779, 99.8147617],
  'Dubai': [25.1974369, 55.2610655],
  'Goa': [15.5958748, 73.7326951]
};

const Map = ({ currentLocation, visitedLocations = [] }) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map && currentLocation && locations[currentLocation]) {
      map.setView(locations[currentLocation], 5);
    }
  }, [map, currentLocation]);

  const customIcon = new L.Icon({
    iconUrl: '/images/go-global-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [41, 41],
    iconAnchor: [20, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const trajectoryPositions = visitedLocations.map(location => locations[location]);

  return (
    <div className="map-container p-4 rounded-lg mb-4" style={{ height: "400px", width: "100%" }}>
      {/* <h3 className="text-lg font-bold mb-2">Journey Map</h3> */}
      <MapContainer 
        center={locations[currentLocation] || [0, 0]} 
        zoom={9} 
        style={{ height: "100%", width: "100%", borderRadius: "15px" }}
        whenCreated={setMap}
        zoomControl={false}
      >
        <TileLayer
          url={`https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg?api_key=${process.env.REACT_APP_STADIA_API_KEY}`}
          maxZoom={16}
        />
        {visitedLocations.map((name) => (
          <Marker 
            key={name} 
            position={locations[name]}
            icon={customIcon}
          >
            <Popup>{name}</Popup>
          </Marker>
        ))}
        {currentLocation && !visitedLocations.includes(currentLocation) && (
          <Marker 
            key={currentLocation} 
            position={locations[currentLocation]}
            icon={customIcon}
          >
            <Popup>{currentLocation}</Popup>
          </Marker>
        )}
        <Polyline positions={trajectoryPositions} color="#F8874D" />
      </MapContainer>
      
    </div>
  );
};

export default Map;