import React, { useState, useEffect, useRef } from 'react';
import './BoardingPass.css';

const BoardingPass = ({ currentLocation, destination, travelTime, onBoardingComplete }) => {
  const [timeLeft, setTimeLeft] = useState(() => {
    const endTime = localStorage.getItem('boardingPassEndTime');
    if (endTime) {
      const remaining = Math.max(0, Math.round((parseInt(endTime, 10) - Date.now()) / 1000));
      return remaining;
    }
    return travelTime / 1000;
  });
  const planeWrapperRef = useRef(null);
  const hasCompletedRef = useRef(false);

  useEffect(() => {
    const endTime = localStorage.getItem('boardingPassEndTime') || (Date.now() + timeLeft * 1000).toString();
    localStorage.setItem('boardingPassEndTime', endTime);

    const timer = setInterval(() => {
      const now = Date.now();
      const remaining = Math.max(0, Math.round((parseInt(endTime, 10) - now) / 1000));
      
      setTimeLeft(remaining);

      if (remaining <= 0 && !hasCompletedRef.current) {
        clearInterval(timer);
        console.log("Boarding complete, moving to next level");
        hasCompletedRef.current = true;
        localStorage.removeItem('boardingPassEndTime');
        onBoardingComplete();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [travelTime, onBoardingComplete]);

  useEffect(() => {
    if (planeWrapperRef.current) {
      planeWrapperRef.current.style.animation = `flight ${timeLeft}s linear`;
    }
  }, [timeLeft]);

  return (
    <div className="boarding-pass">
      <div className="airplane-image"></div>
      <div className="worldmap-image"></div>  
      <div className="airports">
        <div className="airport">
          <div className="airport-code">{currentLocation.slice(0, 3).toUpperCase()}</div>
          <div>{currentLocation}</div>
        </div>
        <div className="airport">
          <div className="airport-code">{destination.slice(0, 3).toUpperCase()}</div>
          <div>{destination}</div>
        </div>
      </div>
      <div className="flight-info">
        <div className="flight-map">
          <div ref={planeWrapperRef} className="plane-wrapper">
            <div className="plane"></div>
          </div>
        </div>
      </div>
      <div className="boarding-info">
      <div className="airline-logo"></div>
        <div className="info-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="info-column" style={{ textAlign: 'center', flex: '1' }}>
            <span className="info-label">Travel Time</span>
            <div className="info-data">
              {timeLeft > 3600
                ? `${Math.floor(timeLeft / 3600)}:${Math.floor((timeLeft % 3600) / 60)
                    .toString()
                    .padStart(2, '0')}:${Math.floor(timeLeft % 60)
                    .toString()
                    .padStart(2, '0')}`
                : timeLeft > 60
                ? `${Math.floor(timeLeft / 60)}:${Math.floor(timeLeft % 60)
                    .toString()
                    .padStart(2, '0')}`
                : `${Math.ceil(timeLeft)} seconds`}
            </div>
          </div>
          <div className="info-column" style={{ textAlign: 'center', flex: '1' }}>
            <span className="info-label">Status</span>
            <div className="info-data">In flight</div>
          </div>
          <div className="info-column" style={{ textAlign: 'center', flex: '1' }}>
            <span className="info-label">Flight No.</span>
            <div className="info-data">GO 3381</div>
          </div>
        </div>
      </div>
      <div className="passenger-info">
        <div className="info-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="info-column" style={{ textAlign: 'center', flex: '1' }}>
            <span className="info-label">Flight Date</span>
            <div className="info-data">{new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' })}</div>
          </div>
          <div className="info-column" style={{ textAlign: 'center', flex: '1' }}>
            <span className="info-label">Seat</span>
            <div className="info-data">1C</div>
          </div>
          <div className="info-column" style={{ textAlign: 'center', flex: '1' }}>
            <span className="info-label">Class</span>
            <div className="info-data">Business</div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px', fontStyle: 'italic', fontSize: '14px' }}>
          Thank you for flying with us
        </div>
    </div>
  );
}

export default BoardingPass;