import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import config from '../config';
import Map from '../components/Map';
import Quiz from '../components/Quiz';
import BoardingPass from '../components/BoardingPass';

const TOTAL_LEVELS = 5; // Define the total number of levels

const Game = ({ gameState, setGameState, endGame }) => {
  const [quizData, setQuizData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showBoardingPass, setShowBoardingPass] = useState(() => {
    return localStorage.getItem('boardingPassEndTime') !== null;
  });
  const [nextLocation, setNextLocation] = useState(() => {
    return localStorage.getItem('nextLocation') || '';
  });
  const [travelTime, setTravelTime] = useState(() => {
    return parseInt(localStorage.getItem('travelTime'), 10) || 0;
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    const savedIndex = localStorage.getItem('currentQuestionIndex');
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });
  const [isCorrect, setIsCorrect] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [isLevelComplete, setIsLevelComplete] = useState(showBoardingPass);
  const [countdownTime, setCountdownTime] = useState(null);
  const [autoProgressTimer, setAutoProgressTimer] = useState(null);
  const [incorrectMessage, setIncorrectMessage] = useState('');
  const isTransitioning = useRef(false);

  const locations = ['Singapore', 'Bali', 'Koh Tao', 'Dubai', 'Goa'];

  const fetchQuizData = useCallback(async () => {
    try {
      setLoading(true);
      const url = `${config.apiUrl}/api/quiz/${gameState.currentLevel}`;
      console.log("Fetching quiz data from URL:", url);
      const response = await axios.get(url);
      console.log("Fetched quiz data:", JSON.stringify(response.data, null, 2));
      if (response.data && response.data.questions) {
        setQuizData(response.data);
        setLoading(false);
      } else {
        throw new Error('Invalid quiz data received');
      }
    } catch (err) {
      console.error('Error fetching quiz data:', err);
      console.error('Response:', err.response);
      setError(`Failed to fetch quiz data. Error: ${err.message}`);
      setLoading(false);
      setQuizData(null);
    }
  }, [gameState.currentLevel]);

  const handleNextQuestion = useCallback(() => {
    if (autoProgressTimer) {
      clearInterval(autoProgressTimer);
      setAutoProgressTimer(null);
    }
    setCountdownTime(null);

    if (quizData && quizData.questions && currentQuestionIndex < quizData.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setIsCorrect(false);
      setShowNextButton(false);
      setError(null);
    } else if (quizData && quizData.nextLocation) {
      // Level complete, show boarding pass
      setIsLevelComplete(true);
      setShowBoardingPass(true);
      setNextLocation(quizData.nextLocation);
      setTravelTime(quizData.travelTime);
      localStorage.setItem('nextLocation', quizData.nextLocation);
      localStorage.setItem('travelTime', quizData.travelTime.toString());
    } else {
      console.error('Quiz data is not available or incomplete');
      setError('Unable to proceed to the next question. Please try again.');
    }
  }, [autoProgressTimer, quizData, currentQuestionIndex, setIsLevelComplete, setShowBoardingPass, setNextLocation, setTravelTime]);

  const startAutoProgressCountdown = useCallback(() => {
    setCountdownTime(3);
    const timer = setInterval(() => {
      setCountdownTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          handleNextQuestion();
          return null;
        }
        return prevTime - 1;
      });
    }, 1000);
    setAutoProgressTimer(timer);
  }, [handleNextQuestion]);

  const handleQuizSubmit = useCallback(async (solution) => {
    try {
      const response = await axios.post(`${config.apiUrl}/api/submitSolution`, {
        level: gameState.currentLevel,
        solution: solution,
        questionIndex: currentQuestionIndex
      });

      if (response.data.success) {
        setIsCorrect(true);
        setShowNextButton(true);
        startAutoProgressCountdown();

        if (response.data.nextLocation) {
          // All questions for this level are answered correctly
          setNextLocation(response.data.nextLocation);
          setTravelTime(response.data.travelTime);

          // Send boarding pass email
          const userEmail = localStorage.getItem('userEmail');
          if (userEmail) {
            try {
              await axios.post(`${config.apiUrl}/api/send-boarding-pass`, {
                email: userEmail,
                destination: response.data.nextLocation,
                currentLocation: gameState.location
              });
              console.log('Boarding pass email sent successfully');
            } catch (error) {
              console.error('Failed to send boarding pass email:', error.response ? error.response.data : error.message);
            }
          }
        }
      } else {
        setIsCorrect(false);
        setIncorrectMessage('Sorry, that\'s not correct. Please try again.');
        setTimeout(() => {
          setIncorrectMessage(null);
        }, 3000);
      }
    } catch (error) {
      console.error('Error submitting solution:', error);
      setError('Failed to submit solution. Please try again.');
    }
  }, [gameState.currentLevel, currentQuestionIndex, gameState.location, startAutoProgressCountdown]);

  const handleNextLevel = useCallback(() => {
    if (isTransitioning.current) {
      console.log("Already transitioning to next level, ignoring call");
      return;
    }

    isTransitioning.current = true;
    console.log("Starting transition to next level");

    setGameState(prev => {
      const nextLevel = prev.currentLevel + 1;
      console.log("Setting next level:", nextLevel);
      return {
        ...prev,
        currentLevel: nextLevel,
        location: nextLocation
      };
    });

    setCurrentQuestionIndex(0);
    setShowBoardingPass(false);
    setIsLevelComplete(false);
    setIsCorrect(false);
    setShowNextButton(false);
    setError(null);
    setQuizData(null);
    localStorage.removeItem('boardingPassEndTime');
    localStorage.removeItem('nextLocation');
    localStorage.removeItem('travelTime');

    console.log("Finished setting up next level");

    setTimeout(() => {
      isTransitioning.current = false;
      console.log("Reset transitioning flag");
    }, 100);
  }, [nextLocation, setGameState]);

  useEffect(() => {
    console.log("Current game level:", gameState.currentLevel);
    console.log("Current location:", gameState.location);
  }, [gameState.currentLevel, gameState.location]);

  useEffect(() => {
    console.log("Fetching quiz data for level:", gameState.currentLevel);
    if (!showBoardingPass) {
      setQuizData(null); // Reset quizData to trigger loading state
      setLoading(true);
      fetchQuizData();
    }
  }, [fetchQuizData, showBoardingPass, gameState.currentLevel]);

  useEffect(() => {
    localStorage.setItem('currentQuestionIndex', currentQuestionIndex.toString());
  }, [currentQuestionIndex]);

  useEffect(() => {
    return () => {
      if (autoProgressTimer) {
        clearInterval(autoProgressTimer);
      }
    };
  }, [autoProgressTimer]);

  useEffect(() => {
    setError(null); // Reset error when component mounts or level changes
  }, [gameState.currentLevel]);

  useEffect(() => {
    let timer;
    if (isCorrect && showNextButton) {
      timer = setInterval(() => {
        setCountdownTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            handleNextQuestion();
            return null;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isCorrect, showNextButton, handleNextQuestion]);

  return (
    <div className="p-4">
      <div className="flex justify-center mb-4">
        <img src="/images/go-global-logo.png" alt="Terminal 2 Hearts Logo" className="w-266 h-200 mb-5" />
      </div>
      {!isLevelComplete ? (
        <div className="flex flex-col md:flex-row gap-4 items-center justify-center ">
          <div className="w-full md:w-1/2">
            <Map 
              currentLocation={gameState.location} 
              visitedLocations={locations.slice(0, gameState.currentLevel)}
            />
          </div>
          <div className="w-full md:w-1/2">
            {loading ? (
              <p>Loading quiz...</p>
            ) : error && !quizData ? (
              <p className="text-red-500">{error}</p>
            ) : quizData && quizData.questions && quizData.questions.length > 0 ? (
              <>
                <Quiz 
                  data={quizData.questions[currentQuestionIndex]}
                  onSubmit={handleQuizSubmit}
                  isCorrect={isCorrect}
                  showNextButton={showNextButton}
                  onNextQuestion={handleNextQuestion}
                  countdownTime={countdownTime}
                  incorrectMessage={incorrectMessage}
                />
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </>
            ) : (
              <p>Loading question...</p>
            )}
          </div>
        </div>
      ) : gameState.currentLevel === TOTAL_LEVELS ? (
        <div className="flex flex-col items-center justify-center mt-40">
          <h2 className="text-2xl font-bold mb-4">Congratulations, boo!</h2>
          <p className="mb-4 text-center"> You've successfully completed all levels! <br></br>There's just one more thing...</p>
          <button onClick={endGame} className="btn btn-primary mt-4">
            Take me there
          </button>
        </div>
      ) : (
        <BoardingPass 
          currentLocation={gameState.location}
          destination={nextLocation}
          travelTime={travelTime}
          onBoardingComplete={handleNextLevel}
        />
      )}
    </div>
  );
};

export default Game;