import React, { useState, useEffect, useRef } from 'react';
import Confetti from 'react-confetti';

const Finale = ({ restartGame }) => {
  const [windowDimension, setWindowDimension] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [showConfetti, setShowConfetti] = useState(false);
  const [showFinalQuestion, setShowFinalQuestion] = useState(true);
  const [noButtonPosition, setNoButtonPosition] = useState({ left: 0, top: 0 });
  const noButtonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimension({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleYesClick = () => {
    setShowFinalQuestion(false);
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 10000);
  };

  const handleNoHover = () => {
    if (noButtonRef.current) {
      const buttonWidth = noButtonRef.current.offsetWidth;
      const buttonHeight = noButtonRef.current.offsetHeight;
      const maxLeft = windowDimension.width - buttonWidth;
      const maxTop = windowDimension.height - buttonHeight;

      setNoButtonPosition({
        left: Math.random() * maxLeft,
        top: Math.random() * maxTop,
      });
    }
  };

  return (
    <div className="hero min-h-screen">
      {showConfetti && (
        <Confetti
          width={windowDimension.width}
          height={windowDimension.height}
          recycle={false}
          numberOfPieces={500}
        />
      )}
      <div className="hero-content text-center">
        <div className="max-w-md">
          {showFinalQuestion ? (
            <>
              <div className="flex justify-center mb-4">
                <img src="/images/go-global-logo.png" alt="Terminal 2Hearts Logo" className="w-266 h-200 mb-5" />
              </div>
              <h1 className="text-3xl mb-8">Will you be my valentine?</h1>
              <div className="flex justify-center space-x-4" style={{ position: 'relative', height: '100px', width: '300px' }}>
                <button className="btn btn-primary" onClick={handleYesClick}>Yes</button>
                <button 
                  ref={noButtonRef}
                  className="btn btn-default" 
                  style={{
                    position: 'absolute',
                    left: `${Math.max(0, Math.min(noButtonPosition.left, 300))}px`,
                    top: `${Math.max(0, Math.min(noButtonPosition.top, 300))}px`,
                    transition: 'all 0.2s ease-in-out'
                  }}
                  onMouseEnter={handleNoHover}
                  onClick={handleNoHover}
                >
                  No
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-center mb-4">
                <img src="/images/go-global-logo.png" alt="Terminal 2Hearts Logo" className="w-266 h-200 mb-5" />
              </div>
              <h1 className="text-5xl font-bold">Sahi Jawaab! Congratulations!</h1>
              <p className="py-6">You've completed our journey around the world!</p>
              <p className="py-6">Thank you for playing with me! Hope you had more fun than the pain making this game caused me. I love you a lot, boo, more than these terminal hearts can convey. I'm sorry for being difficult most times, I try to be better and I love you for sticking with me. You're the best and I love you more than you know.</p>

              <div className="w-full">
                <div className="w-52 mx-auto">
                  <div className="bg-white p-2 shadow-lg transform rotate-2">
                    <video 
                      src="/images/game-header.mp4" 
                      alt="Header Video" 
                      className="w-52 h-auto"
                      autoPlay
                      loop
                      muted
                      playsInline
                    >
                      Your browser does not support the video tag.
                    </video>
                    <div className="mt-2 text-center text-gray-700 text-xs font-handwriting">
                      Where to next?
                    </div>
                  </div>
                </div>
              </div>

            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Finale;