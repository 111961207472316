import React, { useState } from 'react';

const Home = ({ startGame }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'gupchu') {
      localStorage.setItem('userEmail', 'osborne.saldanha@gmail.com'); // Replace with the desired email address
      startGame();
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="mx-auto items-center justify-center">

          <div className="flex justify-center">
            <img src="/images/go-global-logo.png" alt="Terminal 2Hearts Logo" className="w-266 h-200 mb-5" />
          </div>

      <div className="hero-content flex flex-col md:flex-row text-center md:text-left mt-10">
        <div className="max-w-md text-center p-3">
          
        <div className="w-full">
          <div className="w-52 mx-auto">
            <div className="bg-white p-2 shadow-lg transform rotate-2">
              <video 
                src="/images/game-header.mp4" 
                alt="Header Video" 
                className="w-52 h-auto"
                autoPlay
                loop
                muted
                playsInline
              >
                Your browser does not support the video tag.
              </video>
              <div className="mt-2 text-center text-gray-700 text-xs font-handwriting">
                Is my travel buddy ready?
              </div>
            </div>
          </div>
        </div>

          <p className="py-6">Come on a journey with me through our relationship, solving puzzles and unlocking memories along the way.</p>
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              placeholder="Enter password"
              className="input input-bordered w-full max-w-xs mb-2 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoFocus
            />
            {error && (
              <div className="text-error mt-4">
                {error}
                {setTimeout(() => setError(''), 3000)}
              </div>
            )}
            <button type="submit" className="btn btn-primary mt-4">Start Adventure</button>
          </form>
        </div>
        <div className="max-w-md mb-8 md:mb-0 md:mr-8 p-3">
          <p className="py-2">Hi boo, this year has not been the greatest for you. Yet, you power through it all with grace, strength and resilience. I'm hoping with this game, you get to take a break and dream a little with me. Reminisce on all the places we've travelled together.</p>
          <p className="py-2">The game itself is fairly straight forward. You will be given a series of puzzles and challenges that you must solve in order to progress. Simple right? This will be a cakewalk for you, I have no doubts.</p>
          <p className="py-2">There are no short cuts, no hints, no cutting time or anything. The game progresses in real time, so take your time and enjoy the journey.</p>
          <p className="py-2">Love you mostest, <br></br>Osborne</p>
        </div>
      </div>
    </div>
  );
};

export default Home;